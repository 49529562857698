import Masonry from 'masonry-layout'
import imagesLoaded from "imagesloaded";

(function ($) {
    let masonryVar;

    function BSPMasonry() {
        console.log('BSPMasonry initialization');
        this.setup();
    }

    BSPMasonry.prototype.setup = function () {
        let objectName = '.fb-masonry-grid';
        let itemName = '.fb-masonry-item';
        if ($(objectName).length) {
            this.addMasonryLogic(objectName, itemName);
        }

        objectName = '.product-item-row';
        itemName = '.product-item-col';
        if ($(objectName).length) {
            this.addMasonryLogic(objectName, itemName);
        }
        console.log(masonryVar);
    }

    BSPMasonry.prototype.addMasonryLogic = function (objectName, itemName) {
        let self = this;

        if (!$(objectName).length) {
            return;
        }

        imagesLoaded(objectName, function () {
            let grid = document.querySelector(objectName);
            self.masonryVar = new Masonry(grid, {
                // options...
                itemSelector: itemName,
                columnWidth: itemName,
            });
        });
    }

    BSPMasonry.prototype.rebuildMasonry = function () {
        if (typeof this.masonryVar !== 'undefined') {
            let objectName = '.product-item-row';
            let itemName = '.product-item-col';
            imagesLoaded(objectName, function () {
                let grid = document.querySelector( objectName );
                new Masonry( grid, {
                    // options...
                    itemSelector: itemName,
                    columnWidth: itemName,
                } );
            });

            //this.masonryVar.layout();
        } else {
            console.log('false rebuild');
        }
    }

    window.BSPMasonry = BSPMasonry;

})(jQuery);