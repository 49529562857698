//CSS import
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/fontawesome.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'swiper/swiper.min.css';
import 'datatables.net-responsive-bs5/css/responsive.bootstrap5.min.css';
import '../css/fonts.css';
//import '../css/style.css';

//JS import
require('./BSPDataTables');
require('./BSPSwiper');
require('./BSPMasonry');
require('./SingleLibrary');
require('./ShopFilter');
require('./UserType');
require('./Snippets');
require('./SingleProductModals');
require('./OrganisationEmailModal');
require('./MembersModal');
require('./OTPForm');

//Common scripts
jQuery(document).ready(function ($) {

    // ================== Dropdown  ======================= //
    if ($(window).width() < 1200) {
        $(function () {
            $('#myUL li').click(function (e) {
                e.stopPropagation();
                $(this).children('ul').stop().slideToggle(400);
            });
        });
    } else {
        $('#myUL li').hover(function () {
            $(this).children('ul').stop().slideToggle(400);
        });
    }

    if ($(window).width() < 1200) {
        $(function () {
            $('#myUL2 li').click(function (e) {
                e.stopPropagation();
                $(this).children('ul').stop().slideToggle(400);
            });
        });
    } else {
        $('#myUL2 li').hover(function () {
            $(this).children('ul').stop().slideToggle(400);
        });
    }

    // ====================== footer ======================= //

    // Footer menu collapse jquiery
    if ($(window).width() <= 991) {
        $('.footer-menu-links').removeClass('show');
    }

    $(window).resize(function () {
        if ($(window).width() <= 991) {
            $('.footer-menu-links').removeClass('show');
        } else {
            $('.footer-menu-links').addClass('show');
        }
    });
});

//From Custom-JS
// ====== OnScroll Header Class Add and Remove Js ====== //

let scrollpos = window.scrollY
const header = document.querySelector("header")
const header_height = header.offsetHeight //TODO: Unused by some reason

const add_class_on_scroll = () => header.classList.add("shrink")
const remove_class_on_scroll = () => header.classList.remove("shrink")

window.addEventListener('scroll', function () {
    scrollpos = window.scrollY;

    if (scrollpos >= 50) {
        add_class_on_scroll()
    } else {
        remove_class_on_scroll()
    }
})


//========================== Header Menu ========================== //
let hamburgerMenu = document.getElementsByClassName("hamburger-menu")[0]
let navbarCollaspe = document.getElementsByClassName("navbar-collapse")[0]
let overlay = document.getElementsByClassName("overlay")[0]
let collaspeClose = document.getElementsByClassName("collapse-close")[0]

hamburgerMenu.addEventListener("click", openMobileMenu)
collaspeClose.addEventListener("click", closeModal)
overlay.addEventListener("click", closeModal)


//============== Mobile Navigation Open ==============
function openMobileMenu() {
    navbarCollaspe.classList.add("show")
    overlay.classList.add("show-overlay")
    document.body.classList.toggle("overflow-hidden")
}

//============== Mobile Navigation Close ==============
function closeModal() {
    navbarCollaspe.classList.remove("show")
    overlay.classList.remove("show-overlay")
    document.body.classList.toggle("overflow-hidden")
    // ======= filter sidebar
    filterSidebar.classList.remove("filter-sidebar-open");
}

//======= Close Modal By Escape Key
document.addEventListener("keydown", function (event) {
    if (event.key === "Escape") {
        navbarCollaspe.classList.remove("show")
        overlay.classList.remove("show-overlay")
        document.body.classList.toggle("overflow-hidden")
    }
})


//======= Header Search


let searchIcon = document.querySelector('.navber-action-search');
let searchForm = document.querySelector('.header-search');
let searchClose = document.querySelector('.search-panel-close');

searchIcon.addEventListener("click", function () {
    searchForm.classList.toggle("header-search-open");

})
searchClose.addEventListener("click", function () {
    searchForm.classList.toggle("header-search-open");

})


// ===================== filter sidebar

let filterSidebar = document.querySelector(".main-filter-sidebar");
let filterClose = document.querySelector(".filter-close");
let filterBtn = document.querySelector(".filter-btn");

if (filterBtn) {
    filterBtn.addEventListener("click", function () {
        filterSidebar.classList.toggle("filter-sidebar-open");
        overlay.classList.toggle("show-overlay")
        document.body.classList.toggle("overflow-hidden")
    });
}

if (filterClose) {
    filterClose.addEventListener("click", function () {
        filterSidebar.classList.remove("filter-sidebar-open");
        overlay.classList.remove("show-overlay")
        document.body.classList.remove("overflow-hidden")
    });
}
