import Masonry from 'masonry-layout'
import imagesLoaded from "imagesloaded";

(function ($) {
    let masonry;

    let filter = {
        developmentAge: [],
        productCategory: 0,
        page: 1,
        search: '',
        isApp: false,
        isOnlineResources: false
    }

    function ShopFilter(options) {
        console.log('ShopFilter initialization');
        if (!options) {
            throw new Error('No options passed to ShopFilter constructor');
        } else if (!options.apiRoute) {
            throw new Error('No option `apiRoute` passed to ShopFilter constructor');
        }

        this.options = $.extend({}, ShopFilter.defaults, options);
        console.log(this.options);
        this.setup();
    }

    ShopFilter.prototype.setup = function () {
        let self = this;
        let body = $('body');

        if (self.options.isApp === 'true') {
            filter.isApp = true;
        }

        if (self.options.isOnlineResources === 'true') {
            filter.isOnlineResources = true;
        }

        if (self.options.productCategory) {
            filter.productCategory = self.options.productCategory;
        }

        if (self.options.developmentAge !== '0') {
            filter.developmentAge = [self.options.developmentAge];
        }

        if (self.options.search) {
            filter.search = self.options.search;
        }

        $('#age-range input').on('change', function (event) {
            event.preventDefault();
            self.changeAgeRange();
        });

        /*
        $('.category-filter a').on('click', function (event) {
            event.preventDefault();
            let value = $(event.target).data('value');
            filter.productCategory = parseInt(value);
            location.href = $(event.target).data('url');
            //self.send(false);
        });
         */

        //Search
        $('#search_submit').on('click', function (event) {
            event.preventDefault();
            self.checkSearchInput();
        });

        $('#input_search').on('keydown', function (e) {
            if (e.which === 13) {
                self.checkSearchInput();
            }
        });

        //Clear filter
        $('#filter_clear_all').on('click', function (event) {
            event.preventDefault();
            self.resetFilter();
            self.send(true);
        });

        //Pagination
        body.on('click', '#pagination a.page-link', function (event) {
            event.preventDefault();
            filter.page = parseInt($(event.target).data('page'));
            self.send(false);
        });
    }

    ShopFilter.prototype.changeAgeRange = function () {
        let values = $('#age-range input:checked');
        filter.developmentAge = [];

        $.each(values, function (key, value) {
            filter.developmentAge.push(parseInt($(value).val()));
        });
        this.send(false);
    };

    /**
     * Check search input
     */
    ShopFilter.prototype.checkSearchInput = function () {
        filter.search = $('#input_search').val();
        filter.page = 1;
        this.send(false);
    }

    /**
     * Reset filter after reload
     */
    ShopFilter.prototype.resetFilter = function () {
        this.resetFilterForms();

        let isAppOld = filter.isApp;
        let isOnlineResourcesOld = filter.isOnlineResources;
        let arOld = filter.developmentAge;

        filter = {
            productCategory: 0,
            page: 1,
            search: '',
            developmentAge: arOld,
            isApp: isAppOld,
            isOnlineResources: isOnlineResourcesOld
        }
    };

    ShopFilter.prototype.resetFilterForms = function () {
        $('#age-range input[type=checkbox]').prop('checked', false);
        $('#input_search').val('');
    }

    ShopFilter.prototype.send = function (reset) {
        console.log(filter);

        if (reset) {
            $('#clear-filter-btn').hide();
        } else {
            $('#clear-filter-btn').show();
        }

        let self = this;
        $.ajax({
            type: 'post',
            url: self.options.apiRoute,
            data: filter,
            dataType: 'json',
            beforeSend: function (xhr) {
                xhr.setRequestHeader('X-WP-Nonce', self.options.nonce);
                $('#page-content').empty();
                disableInputs();
                showSpinner();
                let toScrollBlock = $('.filter-category-wrapper').closest('.clear-fix');
                if (toScrollBlock.length) {
                    $('html, body').animate({
                        scrollTop: toScrollBlock.offset().top - $('header.fixed-top').height(),
                    }, 1);
                }
            },
            success: function (response) {
                hideSpinner();
                parseResponse(response);
            },
            error: function (data) {
                enableInputs();
                hideSpinner();
                console.log(data);
            }
        });
    }

    function showSpinner() {
        $('#filter-spinner').show();
    }

    function hideSpinner() {
        $('#filter-spinner').hide();
    }

    function disableInputs() {
        $('.main-filter-sidebar').find('input, button').prop('disabled', true);
    }

    function enableInputs() {
        $('.main-filter-sidebar').find('input, button').prop('disabled', false);
    }

    function parseResponse(response) {
        let self = this;
        console.log(response);
        if (!response.status) {
            console.log('Response error');
            return;
        }

        let emptyMessage = $('#empty-message');
        if (response.data) {
            emptyMessage.hide();
        } else {
            emptyMessage.show();
        }

        $('#page-content').empty().append(response.data);
        $('#pagination').empty().append(response.pagination);
        $('#title').empty().append('Filter Results');
        $('#from-to-total-text').empty().append(response.fromToTotalText);

        let objectName = '.product-item-row';
        let itemName = '.product-item-col';

        window.bspMasonry.rebuildMasonry();

        enableInputs();
    }

    window.ShopFilter = ShopFilter;

})(jQuery);