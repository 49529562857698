(function ($) {

    function SingleLibrary(options) {
        console.log('SingleLibrary initialization');
        if (!options) {
            throw new Error('No options passed to SingleLibrary constructor');
        } else if (!options.apiRoute) {
            throw new Error('No option `apiHost` passed to SingleLibrary constructor');
        } else if (!options.postID) {
            throw new Error('No option `postID` passed to SingleLibrary constructor');
        } else if (!options.nonce) {
            throw new Error('No option `nonce` passed to SingleLibrary constructor');
        }

        this.options = $.extend({}, SingleLibrary.defaults, options);
        console.log(this.options);
        this.setup();
    }

    SingleLibrary.prototype.setup = function () {
        let self = this;

        $('#parents-access-form').on('submit', function (event) {
            event.preventDefault();
            self.sendForm();
        });
    }

    SingleLibrary.prototype.sendForm = function () {
        console.log('Send form');

        let parentsData = {
            'name': $('#parents_name').val(),
            'email': $('#parents_email').val(),
            'postID': this.options.postID
        };
        this.send(parentsData);
    }

    SingleLibrary.prototype.send = function (parentsData) {
        let self = this;
        $.ajax({
            type: 'post',
            url: self.options.apiRoute,
            data: parentsData,
            dataType: 'json',
            beforeSend: function (xhr) {
                xhr.setRequestHeader('X-WP-Nonce', self.options.nonce);
                disableInputs();
            },
            success: function (response) {
                parseResponse(response);
            },
            error: function (data) {
                enableInputs();
                console.log(data);
            }
        });
    }

    function disableInputs() {
        $('#parents-access-form').find('input, button').prop('disabled', true);
    }

    function enableInputs() {
        $('#parents-access-form').find('input, button').prop('disabled', false);
    }

    function parseResponse(response) {
        console.log(response);
        location.reload();
        /*
        let messageBlock = $('#message-block');
        messageBlock.empty();
        if (response.status === 'success') {
            messageBlock.show().append(''); //TODO: Add message
        } else {
            messageBlock.show().append(''); //TODO: Add message
            enableInputs();
        } */
        enableInputs();
    }

    window.SingleLibrary = SingleLibrary;

})(jQuery);