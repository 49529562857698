(function ($) {

    function SingleProductModals(options) {
        console.log('SingleLibrary initialization');
        if (!options) {
            throw new Error('No options passed to SingleProductModals constructor');
        }

        this.options = $.extend({}, SingleProductModals.defaults, options);
        console.log(this.options);
        this.setup();
    }

    SingleProductModals.prototype.setup = function () {
        this.initSchoolModal();
        this.initOrganisationsModal();
    }

    SingleProductModals.prototype.initSchoolModal = function () {

        let schoolModal = $('#single-product-modal.school-modal');
        if (schoolModal.length > 0) {
            console.log(6660);
            let addSurveyButton = $('#add-survey-button');
            let removeSurveyButton = $('#remove-survey-button');

            schoolModal.find('#choose_school_count input[type=radio]').on('change', function (event) {
                console.log(124);
                event.preventDefault();
                console.log(addSurveyButton);
                let inputs = $(this).closest('.modal-body').find('input[type=text]');
                if ($(this).val() !== 'multiple-school') {
                    addSurveyButton.hide();
                    inputs.hide();
                    inputs.first().show();
                    $('.inp-block').find('.remove-survey-button').hide();
                } else {

                    addSurveyButton.show();
                    inputs.show();

                    if ($('.inp-block').length > 1) {
                        $('.inp-block').find('.remove-survey-button').show();
                    } else if ($('.inp-block').length === 1) {
                        $('.inp-block').find('.remove-survey-button').hide();
                    }
                }
            });

            addSurveyButton.on('click', function (event) {
                event.preventDefault();

                let iBlock = $(this).closest('.input-wrapper').find('.inp-block').last().clone();
                console.log(iBlock);

                iBlock.find('input[type=text]').val('');
                addSurveyButton.before(iBlock);
                $('.inp-block').find('.remove-survey-button').not(":eq(0)").show();
            });

            $('body').on('click', '.remove-survey-button', function (event) {
                event.preventDefault();
                if ($('.inp-block').length > 1) {
                    $(this).closest('.inp-block').remove();
                    if ($('.inp-block').length === 1) {
                        $('.inp-block').find('.remove-survey-button').hide();
                    }
                }
            });
        }
    }

    SingleProductModals.prototype.initOrganisationsModal = function () {
        let organisationModal = $('#single-product-modal.organisation-modal');
        if (organisationModal.length > 0) {
            let addSurveyButton = $('#add-survey-button');
            addSurveyButton.on('click', function (event) {
                event.preventDefault();
                let block = $(this).closest('.input-wrapper').find('.inp-block').last().clone();
                block.find('input[type=text]').val('');
                addSurveyButton.before(block);
                $('.inp-block').find('.remove-department-button').not(":eq(0)").show();
            });

            let body = $('body');

            body.on('click', '.remove-department-button', function (event) {
                event.preventDefault();
                if ($('.inp-block').length > 1) {
                    $(this).closest('.inp-block').remove();
                    if ($('.inp-block').length === 1) {
                        $('.inp-block').find('.remove-department-button').hide();
                    }
                }
            });

            body.on('click', '.btn-add-to-cart, button[name="add-to-cart"], .btn-addto-quote-cart', function (event) {
                if (!$('#team_name').val()) {
                    $('.team-name-error').show();
                    return;
                }
                $('.team-name-error').hide();

                var productModal = new bootstrap.Modal(document.getElementById('single-product-modal'), {
                    keyboard: false
                });
                productModal.toggle();

                $('#team_name_modal').val($('#team_name').val());
                $('#quantity_modal').val($('.quantity input[name="quantity"]').val());
                console.log($('.quantity input[name="quantity"]').val());
            });

            body.on('click', '#quotes_form input[name="user-numbers"]', function (event) {
                $('.quantity input[name="quantity"]').val($('input[name="user-numbers"]').val());
            });

            body.on('keyup', '#quotes_form input[name="user-numbers"]', function (event) {
                console.log($('input[name="user-numbers"]').val());
                $('.quantity input[name="quantity"]').val($('input[name="user-numbers"]').val());
            });
        }
    }

    window.SingleProductModals = SingleProductModals;

})(jQuery);