(function ($) {
    function OrganisationEmailModal(options) {
        console.log('OrganisationEmailModal initialization');
        if (!options) {
            throw new Error('No options passed to OrganisationEmailModal constructor');
        } else if (!options.apiRoute) {
            throw new Error('No option `apiHost` passed to OrganisationEmailModal constructor');
        } else if (!options.nonce) {
            throw new Error('No option `nonce` passed to OrganisationEmailModal constructor');
        } else if (!options.orderID) {
            throw new Error('No option `orderID` passed to OrganisationEmailModal constructor');
        } else if (!options.successMessage) {
            throw new Error('No option `orderID` passed to OrganisationEmailModal constructor');
        }

        this.options = $.extend({}, OrganisationEmailModal.defaults, options);
        this.setup();
    }

    OrganisationEmailModal.prototype.setup = function () {
        let self = this;
        $('#organisation-order-email-form').on('submit', function (event) {
            event.preventDefault();
            self.send({
                'orderID': self.options.orderID,
                'email': $('#organisation-order-email').val()
            })
        });
    }

    OrganisationEmailModal.prototype.send = function (data) {
        console.log(data);
        let self = this;
        $.ajax({
            type: 'post',
            url: self.options.apiRoute,
            data: data,
            dataType: 'json',
            beforeSend: function (xhr) {
                xhr.setRequestHeader('X-WP-Nonce', self.options.nonce);
                disableInputs();
            },
            success: function (response) {
                if (response.status === 'success') {
                    $('#organisation-order-modal .modal-body').empty().append(self.options.successMessage);
                }
            },
            error: function (data) {
                console.log(data);
            }
        });
    }

    function disableInputs() {
        $('#organisation-order-modal').find('input, button').prop('disabled', true);
    }

    window.OrganisationEmailModal = OrganisationEmailModal;

})(jQuery);