import CryptoJS from 'crypto-js';

(function ($) {
    function OTPForm(options) {
        console.log('OTPForm initialization');
        if (!options) {
            throw new Error('No options passed to OTPForm constructor');
        }

        this.options = $.extend({}, OTPForm.defaults, options);
        this.setup();
    }

    OTPForm.prototype.setup = function () {
        let self = this;
        $('#otp-form').on('submit', function (event) {
            event.preventDefault();
            let code = '';
            $.each($(this).find('input[type=text]'), function (key, input) {
                code += $(input).val();
            });

            let hash = CryptoJS.SHA1(code);
            console.log(code);
            console.log(CryptoJS.enc.Hex.stringify(hash));
            self.send({
                'action': 'check',
                'otp_hash': CryptoJS.enc.Hex.stringify(hash)
            });
        });

        $('#resend').on('click', function (event) {
            event.preventDefault();
            $(this).parent().empty().append(self.options.newCodeSentMessage);
            self.send({
                'action': 'resend',
            });
        });
    }

    OTPForm.prototype.send = function (data) {
        let self = this;
        $.ajax({
            type: 'post',
            url: self.options.apiRoute,
            data: data,
            dataType: 'json',
            beforeSend: function (xhr) {
                xhr.setRequestHeader('X-WP-Nonce', self.options.nonce);
                self.disableInputs();
                $('#message').hide().empty();
            },
            success: function (response) {
                self.parseResponse(response);
            },
            error: function (data) {
                self.enableInputs();
                self.parseError(data);
            }
        });
    }

    OTPForm.prototype.disableInputs = function () {
        $('#otp-form').find('input, button').prop('disabled', true);
    }

    OTPForm.prototype.enableInputs = function () {
        $('#otp-form').find('input, button').prop('disabled', false);
    }

    OTPForm.prototype.parseResponse = function (response) {
        console.log(response);
        if (response.status === 'success') {
            window.location.href = this.options.redirectURL
        }
    }

    OTPForm.prototype.parseError = function (data) {
        console.log(data);

        let message = $('#message');
        switch (data.status) {
            case 403:
                message.append(this.options.wrongCodeError);
                break;
            case 404:
                message.append(this.options.accessDeniedError);
                break;
            case 500:
            default:
                message.append(this.options.serverError);
                break;
        }
        message.show();
    }

    window.OTPForm = OTPForm;

})(jQuery);