(function ($) {
    require('datatables.net-bs5');
    require('datatables.net-responsive-bs5');

    function BSPDataTables(options) {
        if (!options) {
            throw new Error('No options passed to BSPDataTables constructor');
        }
        console.log('BSPDataTables initialization');
        this.options = $.extend({}, BSPDataTables.defaults, options);
        this.addDownloadsTable();
        this.addDigitalResourceTable();
        this.addSnippetsTable();
        this.addPlanResourcesTable();
        this.addLibraryUserAccessTable();
        this.addOrdersTable();
    }

    /**
     * Add table on My Account downloads page
     */
    BSPDataTables.prototype.addDownloadsTable = function () {
        let table = $("#pdf-resource-sale-listing");
        if (!table.length) {
            return;
        }

        let dataTable = table.DataTable({
            searching: true,
            lengthChange: false,
            "lengthMenu": [[5, 10, 15, 20, -1], [5, 10, 15, 20, "All"]],
            "autoWidth": false,
            "pageLength": 50,
            "responsive": {
                detail: true,
            },
            order: [[0, 'asc']],
            "aaSorting": [],
            "columnDefs": [
                {
                    responsivePriority: 1,
                    targets: 0
                },

            ],
        });

        $('#datatableSearch').on('keyup', function () {
            console.log(this.value);
            dataTable.columns(0).search(this.value).draw();
        });
    }

    /**
     * Add table on My Account online resources page
     */
    BSPDataTables.prototype.addDigitalResourceTable = function () {
        let table = $('#online-resource-sale-listing');
        if (!table.length) {
            return;
        }

        let dataTable = table.DataTable({
            searching: true,
            lengthChange: false,
            "lengthMenu": [[5, 10, 15, 20, -1], [5, 10, 15, 20, "All"]],
            "autoWidth": false,
            "responsive": {
                detail: true,
            },
            "columnDefs": [
                {
                    responsivePriority: 1,
                    className: '',
                    targets: 0
                },
                {
                    "orderable": false,
                    "targets": 5
                }
            ],
        });

        $('#datatableSearch').on('keyup', function () {
            console.log(this.value);
            dataTable.columns(0).search(this.value).draw();
        });
    }

    /**
     * Add table on My Account snippets page
     */
    BSPDataTables.prototype.addSnippetsTable = function () {
        let table = $('#snippets-resource-listing');
        if (!table.length) {
            return;
        }

        let dataTable = table.DataTable({
            searching: true,
            lengthChange: false,
            "lengthMenu": [[5, 10, 15, 20, -1], [5, 10, 15, 20, "All"]],
            "autoWidth": false,
            "responsive": {
                detail: true,
            },
            "columnDefs": [
                {responsivePriority: 1, targets: 0},
            ],
        });

        $('#datatableSearch').on('keyup', function () {
            console.log(this.value);
            dataTable.columns(0).search(this.value).draw();
        });
    }

    /**
     * Add table on My Account plan resources page
     */
    BSPDataTables.prototype.addPlanResourcesTable = function () {
        let table = $('#plan_content_resource_table');
        if (!table.length) {
            return;
        }

        let dataTable = table.DataTable({
            searching: true,
            lengthChange: false,
            "lengthMenu": [[5, 10, 15, 20, -1], [5, 10, 15, 20, "All"]],
            "autoWidth": false,
            "responsive": {
                detail: true,
            },
            "columnDefs": [
                {
                    responsivePriority: 1,
                    className: '',
                    targets: 0
                },
                {
                    "orderable": false,
                    "targets": 3
                }
            ],
        });

        $('#datatableSearch').on('keyup', function () {
            console.log(this.value);
            dataTable.columns(0).search(this.value).draw();
        });
    }

    BSPDataTables.prototype.addLibraryUserAccessTable = function () {
        let table = $('#library-access-table');
        if (!table.length) {
            return;
        }

        let dataTable = table.DataTable({
            searching: false,
            lengthChange: false,
            "lengthMenu": [[5, 10, 15, 20, -1], [5, 10, 15, 20, "All"]],
            "autoWidth": false,
            "responsive": {
                detail: true,
            },
            "columnDefs": [
                {
                    responsivePriority: 1,
                    targets: 0
                },
            ],
        });
    }

    BSPDataTables.prototype.addOrdersTable = function () {
        let table = $('#orders-table');
        if (!table.length) {
            return;
        }

        let dataTable = table.DataTable({
            searching: true,
            paging: true,
            lengthChange: false,
            "lengthMenu": [[5, 10, 15, 20, -1], [5, 10, 15, 20, "All"]],
            "autoWidth": false,
            "responsive": {
                detail: true,
            },
            order: [[0, 'desc']],
            "columnDefs": [
                {
                    responsivePriority: 1,
                    targets: 0
                },
                {
                    "orderable": false,
                    "targets": 4
                }
            ],
        });

        $('#datatableSearch').on('keyup', function () {
            console.log(this.value);
            dataTable.columns(0).search(this.value).draw();
        });
    }

    window.BSPDataTables = BSPDataTables;

})(jQuery);