(function ($) {

    function UserType(options) {
        console.log('UserType initialization');
        if (!options) {
            throw new Error('No options passed to UserType constructor');
        } else if (!options.apiRoute) {
            throw new Error('No option `apiHost` passed to UserType constructor');
        } else if (!options.nonce) {
            throw new Error('No option `nonce` passed to UserType constructor');
        }

        this.options = $.extend({}, SingleLibrary.defaults, options);
        console.log(this.options);
        this.setup();
    }

    UserType.prototype.setup = function () {
        let self = this;

        $('#user-type-form').on('submit', function (event) {
            event.preventDefault();
            let value = $(event.target).find('select option:selected').val();
            console.log(value);
            self.send({'userType': value});
        });
    }

    UserType.prototype.send = function (data) {
        let self = this;
        $.ajax({
            type: 'post',
            url: self.options.apiRoute,
            data: data,
            dataType: 'json',
            beforeSend: function (xhr) {
                xhr.setRequestHeader('X-WP-Nonce', self.options.nonce);
                disableInputs();
            },
            success: function (response) {
                if (response.status === 'success') {
                    window.location.href = self.options.redirectURL
                }
            },
            error: function (data) {
                enableInputs();
                console.log(data);
            }
        });
    }

    function disableInputs() {
        $('#parents-access-form').find('input, button').prop('disabled', true);
    }

    function enableInputs() {
        $('#parents-access-form').find('input, button').prop('disabled', false);
    }

    window.UserType = UserType;

})(jQuery);