import Cookies from 'js-cookie'

(function ($) {
	function Snippets(options) {
		console.log('Snippets initialization');
		if (!options) {
			throw new Error('No options passed to Snippets constructor');
		} else if (!options.apiRoute) {
			throw new Error('No option `apiHost` passed to Snippets constructor');
		} else if (!options.nonce) {
			throw new Error('No option `nonce` passed to Snippets constructor');
		} else if (!options.productID) {
			throw new Error('No option `productID` passed to SingleLibrary constructor');
		} else if (!options.orderID) {
			throw new Error('No option `orderID` passed to SingleLibrary constructor');
		}

		this.options = $.extend({}, SingleLibrary.defaults, options);
		console.log(this.options);
		this.setup();
	}

	Snippets.prototype.setup = function () {
		let self = this;

		$('#snippets-resource-listing a.preview-modal').on('click', function (event) {
			self.showPreviewModal($(event.target));
		});

		$('#snippets-resource-listing a.send-modal').on('click', function (event) {
			self.showSendModal($(event.target));
		});

		$('#send_access').on('submit', function (event) {
			event.preventDefault();
			let data = {
				'name': $('#name').val(),
				'email': $('#email').val(),
				'productID': self.options.productID,
				'snippetID': $('#snippet-id').val(),
				'orderID':self.options.orderID,
			};
			self.send(data);
		});
	}

	Snippets.prototype.decodeHtml = function(html) {
		var txt = document.createElement("textarea");
		txt.innerHTML = html;
		return txt.value;
	}

	Snippets.prototype.showPreviewModal = function (obj) {
		console.log(obj);
		let name = obj.data('snippet-name');
		let description = this.decodeHtml(obj.data('snippet-description'));
		let images = JSON.parse(atob(obj.data('snippet-images')));

		let modalImages = $('#modal-preview-images');
		modalImages.empty();
		$.each(images, function (key, value) {
			console.log(value);
			modalImages.append('<img src="' + value + '" alt="">')
		});

		$('#modal-preview-description').empty().append(description);
		$('#modal-preview-name').empty().append(name);
	}

	Snippets.prototype.showSendModal = function (obj) {
		let id = obj.data('snippet-id');
		console.log(id);
		$('#snippet-id').val(id);
	}

	Snippets.prototype.send = function (data) {
		console.log(data);
		let self = this;
		$.ajax({
			type: 'post',
			url: self.options.apiRoute,
			data: data,
			dataType: 'json',
			beforeSend: function (xhr) {
				xhr.setRequestHeader('X-WP-Nonce', self.options.nonce);
				disableInputs();
			},
			success: function (response) {
				Cookies.set('snippet_user', response.user, 1);
				Cookies.set('snippet_email', response.email, 1);

				location.reload();
				if (response.status === 'success') {
					location.reload();
				}
			},
			error: function (data) {

				enableInputs();
				console.log(data);
			}
		});
	}

	function disableInputs() {
		$('#parents-access-form').find('input, button').prop('disabled', true);
	}

	function enableInputs() {
		$('#parents-access-form').find('input, button').prop('disabled', false);
	}

	window.Snippets = Snippets;

})(jQuery);