import Swiper from 'swiper/bundle';

(function ($) {
    function BSPSwiper(options) {
        console.log('BSPSwiper initialization');
        if (!options) {
            throw new Error('No options passed to BSPSwiper constructor');
        } else if (!options.slidersIDs) {
            options.slidersIDs = null;
        }

        console.log(options);

        this.options = $.extend({}, BSPSwiper.defaults, options);
        this.setup();
        this.addHeroSwiper();
        this.addAuthorSwiper();
        this.addUserHeroSwiper();
        this.addTestimonialSwiper();
        this.addSwiperFullImage();
    }

    /**
     * TODO: Need improve this logic
     */
    BSPSwiper.prototype.setup = function () {
        if (!this.options.slidersIDs) {
            return;
        }

        let sliderIDS = $.parseJSON(this.options.slidersIDs);
        console.log(sliderIDS);
        $.each(sliderIDS, function (key, value) {
            if (!$("#featured-swiper-" + value).length) {
                return;
            }

            new Swiper("#featured-swiper-" + value, {
                slidesPerView: 1,
                spaceBetween: 30,   
                loop: true,
                speed: 1000,
                autoplay: {
                    delay: 5000,
                    disableOnInteraction: false,
                },
                navigation: {
                    nextEl: ".featured-swipe-next-" + value,
                    prevEl: ".featured-swipe-prev-" + value,
                },
                breakpoints: {

                    576: {
                        slidesPerView: 1.5,
                    },
            
                    768: {
                        slidesPerView: 2,
                    },
            
                    992: {
                        slidesPerView: 3,
                    },
            
                    1201: {
                        slidesPerView: 4,
                    },
            
            
                },
            });
        });
    }

    BSPSwiper.prototype.addHeroSwiper = function () {
        if (!$('#hero-swiper').length) {
            return;
        }
        new Swiper("#hero-swiper", {
            slidesPerView: 1,
            loop: true,
            speed: 1000,
            autoplay: false,
            autoHeight: true,

            navigation: {
                nextEl: ".hero-swipe-next",
                prevEl: ".hero-swipe-prev",
            },

            pagination: {
                el: ".swiper-pagination",
                clickable: true,
            },

            breakpoints: {
                991: {},
            },
        });
    }

    BSPSwiper.prototype.addAuthorSwiper = function () {
        if (!$('#author-swiper').length) {
            return;
        }

        new Swiper("#author-swiper", {
            slidesPerView: 1,
            spaceBetween: 40,  
            loop: true,
            speed: 1000,

            autoplay: {
                delay: 5000,
                disableOnInteraction: false,
            },


            navigation: {
                nextEl: ".author-swipe-next",
                prevEl: ".author-swipe-prev",
            },

            breakpoints: {
                991: {
                    slidesPerView: 2,            
                },
                
            },
        });
    }

    BSPSwiper.prototype.addUserHeroSwiper = function () {
        if (!$('#user-hero-swiper').length) {
            return;
        }

        new Swiper("#user-hero-swiper", {
            slidesPerView: 1,
            // loop: true,
            speed: 1000,
            // effect: 'fade',
            autoHeight: true,

            autoplay: {
                delay: 5000,
                disableOnInteraction: false,
            },

            navigation: {
                nextEl: ".user-hero-swipe-next",
                prevEl: ".user-hero-swipe-prev",
            },

            pagination: {
                el: ".swiper-pagination",
                clickable: true,
            },

            breakpoints: {
    
                991: {
                    autoHeight: false,  
                },
            },

        });
    }

    BSPSwiper.prototype.addTestimonialSwiper = function () {
        if (!$('#testimonial-swiper').length) {
            return;
        }

        new Swiper("#testimonial-swiper", {
            slidesPerView: 1,    
            spaceBetween: 30,  
            // centeredSlides: 'true',
            loop: true,
            speed: 1000,

            autoplay: {
                delay: 5000,
                disableOnInteraction: false,
            },

            navigation: {
                nextEl: ".testimonial-swipe-next",
                prevEl: ".testimonial-swipe-prev",
            },


            breakpoints: {

                767: {
                    slidesPerView: 2,    
                },        
            
                1200: {
                    spaceBetween: 40,   
                    slidesPerView: 3,             
                },
            },
            
        });
    }

    BSPSwiper.prototype.addSwiperFullImage = function () {
        if (!$('.swipper-full-image').length) {
            return;
        }

        let swiperSmallImage = new Swiper(".swipper-small-image", {
            loop: false,
            spaceBetween: 20,
            slidesPerView: 3,
            watchSlidesProgress: true,
            preventClicks: false,
            preventClicksPropagation: false,
            shortSwipes: false,

            // Responsive breakpoints
            breakpoints: {
                767: {
                    spaceBetween: 10,
                    slidesPerView: 4,
                },
            },
        });

        new Swiper(".swipper-full-image", {
            slidesPerView: 1,
            // loop: true,
            spaceBetween: 0,
            preventClicks: false,
            preventClicksPropagation: false,
            shortSwipes: false,
            autoHeight: true,
            thumbs: {
                swiper: swiperSmallImage,
            },
        });
    }

    window.BSPSwiper = BSPSwiper;

})(jQuery);