(function ($) {

    function MembersModal(options) {
        console.log('MembersModal initialization');
        if (!options) {
            throw new Error('No options passed to MembersModal constructor');
        }

        this.options = $.extend({}, MembersModal.defaults, options);
        this.setup();
    }

    MembersModal.prototype.setup = function () {
        let modal = new bootstrap.Modal(document.getElementById('membership-remove-team-user-modal'), {})

        $('a.remove_member').on('click', function (event) {
            event.preventDefault();
            let link = $(this).attr('href');
            let email = $(this).closest('tr.member').find('td.member-email').text();

            $('#remove-member-link').attr('href', link);
            $('#team-member-email-address').empty().append(email);
            modal.show()
        });
    }

    window.MembersModal = MembersModal;

})(jQuery);